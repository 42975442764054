<template>
    <styled-interface>
        <template #interface-toolbar>
            <dealer-picker-global class="flex md3 xs12" />
        </template>

        <template #interface-heading>
            Play Management
        </template>

        <v-alert
            :value="true"
            type="error"
            class="mb-4 bg-orange">
            <p>
                Meta is changing the way your offline data will be used for attribution by migrating our pixels to a dataset.
                This dataset will be used for both web and offline data.
                Because of this change, you will need to add the new dataset for Offline tracking to your Ads.
                At this point in time, this is a manual change that needs to take place at the ad level in your Ads Manager.
                It will not be set up automatically upon deployment of this playbook.
            </p>
            <p>
                You can follow this <a
                    href="https://www.loom.com/share/0b070f4d1b574351b6a50fada9d4669b"
                    target="_blank">video tutorial here</a>
            </p>
            <p>
                Please reach out to <a
                    href="#"
                    @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Play Review & Deployment')">Support</a> if you need additional assistance.
            </p>
        </v-alert>

        <div
            v-if="loadingDealerPlays"
            class="text-xs-center py-5">
            <loader label="Checking for pending deployments..." />
        </div>


        <styled-card
            v-else-if="dealerPendingPlays.length"
            class="mb-4">
            <template #heading>
                Pending Deployments
            </template>

            <div class="pending-deployments-container">
                <styled-table
                    :headers="dealerPlayHeaders"
                    :items="dealerPendingPlays"
                    min-width="900px"
                    hide-header
                    hide-actions
                    hide-margins>
                    <template #items="{ item, index }">
                        <tr
                            :class="[
                                (index % 2) ? 'row-even' : 'row-odd',
                                (deployedPlay == item.id) ? 'deployed-play' : ''
                            ]">
                            <td class="text-xs-left pa-4">
                                {{ item.play.display_name }}
                            </td>
                            <td class="text-xs-left pa-4">
                                {{ item.status | capitalize }}
                            </td>
                            <td class="text-xs-center pa-4">
                                <template v-if="item.status == 'building'">
                                    <v-progress-linear
                                        color="primary"
                                        :indeterminate="true"
                                        class="deployment-progress" />
                                    <span>
                                        {{ deploymentMessage | truncate(75) }}
                                    </span>
                                </template>
                                <template v-else-if="item.status == 'failed'">
                                    <span class="red--text">
                                        Play building has failed. Please
                                        <a
                                            href="#"
                                            @click.prevent="$store.dispatch('createTicket', {})">
                                            <span>contact support for assistance</span>
                                        </a>
                                        and we'll get you going in no time!
                                    </span>
                                </template>
                            </td>
                            <td class="text-xs-center pa-4 action-button">
                                <div v-if="item.status == 'failed'">
                                    <styled-tooltip
                                        position="top">
                                        <template #content>
                                            <div v-if="getErrorMessage(item)">
                                                Error message: {{ getErrorMessage(item) }}
                                            </div>
                                            <div v-else>
                                                No error message available
                                            </div>
                                        </template>
                                        <action-button
                                            size="24"
                                            icon="refresh"
                                            @click="retryDeployment(item)">
                                            Retry
                                        </action-button>
                                    </styled-tooltip>
                                </div>
                            </td>
                        </tr>
                    </template>
                </styled-table>
            </div>
        </styled-card>

        <div class="mb-5">
            <CampaignTree />
        </div>
    </styled-interface>
</template>

<script>
import DealerPickerGlobal from '../globals/DealerPickerGlobal';
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import StyledTable from '../globals/StyledTable';
import orderBy from 'lodash/orderBy';
import Loader from '../globals/Loader';
import { mapState, mapGetters } from 'vuex';
import getNumberFormats from  '../../helpers/numberFormats';
import CampaignTree from './CampaignTree';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    name: 'ManagePlaybooks',
    title: 'Manage Playbooks',
    components: {
        DealerPickerGlobal,
        StyledInterface,
        StyledCard,
        StyledTable,
        Loader,
        CampaignTree,
        ActionButton,
        StyledTooltip
    },
    props: {
        deployedPlay: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            dealerPlayHeaders: [
                {
                    text: 'Play',
                    align: 'left',
                    sortable: false,
                    value: 'play.display_name',
                    width: '30%'
                },
                {
                    text: 'Status',
                    align: 'left',
                    sortable: false,
                    value: 'play.status',
                    width: '20%'
                },
                {
                    text: 'Progress',
                    sortable: false,
                    width: '50%'
                },
                {
                    text: 'Actions',
                    sortable: false,
                    width: '10%'
                },
            ],
            campaignHeaders: [
                {
                    text: 'Campaign',
                    align: 'left',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: 'Created',
                    value: 'created_time',
                    sortable: true,
                },
                {
                    text: 'Status',
                    value: 'effective_status',
                    sortable: true,
                },
                {
                    text: 'Current Daily Budget',
                    value: 'daily_budget',
                    sortable: true,
                },
                /* {
                    text: 'Monthly Budget Goal',
                    value: 'monthly_budget_goal',
                    sortable: true,
                }, */
                {
                    text: '',
                    value: '',
                    sortable: false,
                },
            ],
            loadingCampaigns: true,
            campaigns: [],
            loadingDealerPlays: true,
            dealerPlays: [],
            error: null,
            numberFormats: getNumberFormats(),
            deploymentMessage: 'Processing play deployment...',
            hasInit: false
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange
        }),
        ...mapGetters([
            'dealerFacebookAdAccountUrl',
            'dealerFacebookAdAccountUrlFiltered'
        ]),
        playBuildingFailed() {
            // If there's failed plays and nothing is building or queued consider building failed entirely
            return (
                this.dealerPlays.some(dealerPlay => dealerPlay.status === 'failed') &&
                !this.dealerPlays.some(dealerPlay => ['building', 'queued'].includes(dealerPlay.status))
            );
        },
        dealerPendingPlays() {
            return this.dealerPlays.filter(dealerPlay => {
                return ['failed', 'building', 'queued'].includes(dealerPlay.status);
            });
        },
        playsNamesWithChangedOptimizationGoal() {
            return this.dealerPlays
                .filter(dealerPlay => dealerPlay?.configuration?.optimization_goal_changed)
                .map(dealerPlay => dealerPlay?.play?.display_name)
                .join(', ');
        }
    },
    watch: {
        playBuildingFailed(hasFailed) {
            // If there's failed plays clear the interval
            if (hasFailed === true) {
                this.clearIntervals();
            }
        },
        dealerPendingPlays(plays, oldPlays) {
            // If the number of plays has changed refresh the campagins
            // since the completed play will have created new campaigns
            if (plays.length !== oldPlays.length) {
                this.getCampaigns();
            }

            // If there's no more plays clear the intervals
            if (!plays.length) {
                this.clearIntervals();
            }
        },
        deployedPlay(play, oldPlay) {
            if (play && play !== oldPlay) {
                this.init();
            }
        }
    },
    created() {
        this.init();
    },
    activated() {
        if (this.hasInit) {
            this.startIntervals();
        }
    },
    deactivated() {
        this.clearIntervals();
    },
    methods: {
        async init() {
            this.loadingCampaigns = true;
            this.loadingDealerPlays = true;

            await Promise.all([
                this.getDealerPlays(),
                this.getCampaigns()
            ]);

            this.initEcho();

            this.loadingCampaigns = false;
            this.loadingDealerPlays = false;

            this.startIntervals();

            this.hasInit = true;
        },
        startIntervals() {
            if (this.dealerPendingPlays.length &&
                !this.playBuildingFailed &&
                !this.playsInterval &&
                !this.campaignsInterval) {

                // Now start checking the status on an interval if there's pending plays
                this.playsInterval = setInterval(() => {
                    this.getDealerPlays();
                }, 2000);

                // Retrieve campaigns less frequently to minimize API calls but still show them
                // building out
                this.campaignsInterval = setInterval(() => {
                    this.getCampaigns();
                }, 10000);
            }
        },
        clearIntervals() {
            clearInterval(this.playsInterval);
            clearInterval(this.campaignsInterval);
        },
        initEcho() {
            // Find the first pending play that has a published a channel
            // @todo support multiple deployments
            const pendingPlay = this.dealerPendingPlays.find(dealerPlay => {
                return (
                    Object.prototype.hasOwnProperty.call(dealerPlay, 'uuid') &&
                    ['building', 'queued'].includes(dealerPlay.status)
                );
            });

            // If there's a published play subscribe to its channel
            if (pendingPlay) {
                let pusherChannel = '';
                let pusherEvent = '';
                if(this.dealerPlays.some(dealerPlay => dealerPlay?.configuration?.play_builder_version === 'v1')) {
                    pusherChannel = 'inventory-channel';
                    pusherEvent = 'InventoryStatusUpdated';
                } else {
                    pusherChannel = 'play-build-channel';
                    pusherEvent = 'PlayBuilderEvent';
                }
                this.$echo.channel(pusherChannel + '-' + pendingPlay.uuid)
                    .listen(pusherEvent, (e) => {
                        this.deploymentMessage = e.message;
                    });
            }
        },
        openCampaign(item) {
            const url = this.dealerFacebookAdAccountUrlFiltered({
                dateRange: this.dateRange,
                campaigns: [ item.id ]
            });
            window.open(url);
        },
        async getDealerPlays() {
            try {
                const response = await this.$apiRepository.getDealerPlays(this.currentDealerId);
                this.dealerPlays = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading dealer plays';
            }
        },
        async getCampaigns() {
            try {
                const fields = [
                    'name',
                    'effective_status',
                    'daily_budget',
                    'adlabels',
                    'created_time'
                ];
                const url = `/dealers/${this.currentDealerId}/graph-api/ad-account/campaigns?fields=${fields.join(',')}`;
                const response = await this.$http.get(url);
                // Add in a friendly label
                const campaigns = response.data.map(campaign => {
                    campaign.campaignEnabled = campaign.effective_status == 'ACTIVE';
                    campaign.monthly_budget_goal = null;
                    return campaign;
                });
                this.campaigns = orderBy(campaigns, ['effective_status', 'name']);
            } catch (error) {
                console.log('Error loading dealer campaigns', error);
                this.error = 'Error loading dealer campaigns';
            }
        },
        async toggleCampaignActive(campaign) {
            this.$http.post(`/dealers/${this.currentDealerId}/toggle-campaign-active`, {
                campaign_id: campaign.id,
                active: campaign.effective_status != 'ACTIVE'
            });
        },
        async retryDeployment(dealerPlay) {
            try {
                await this.$apiRepository.retryDealerPlayDeployment(dealerPlay.dealer_id, dealerPlay.id);
            } catch (error) {
                console.error('Error retrying play deployment', error);
            }
        },
        getErrorMessage(item) {
            return item?.configuration?.exception_message ??
                    item?.configuration?.last_deployment_error;

        }
    }
};
</script>

<style lang="scss">

.pending-deployments-container {
    margin: 2.5rem;
}
.bg-orange.error {
    background-color: #F5A623 !important;
    border-color: #F5A623 !important;
}

.campaign-toggle {
    .v-input__slot {
        margin-bottom: 0 !important;
    }
    .v-messages {
        display: none;
    }
    .v-label {
        font-size: 13px;
    }
}

.deployed-play {
    animation: FadeIn 0.75s ease-in-out forwards;
}

@keyframes FadeIn {
  from {
    background-color: $coral;
  }
  to {
    background-color: transparent;
  }
}
</style>
