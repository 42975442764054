<template>
    <div>
        <template v-if="status === 'ACTIVE'">
            <span class="dot green lighten-3 mr-2" />{{ status | machineToFriendlyName }}
        </template>
        <template v-else-if="status.includes('PAUSED')">
            <span class="dot yellow darken-1 mr-2" />{{ status | machineToFriendlyName }}
        </template>
        <template v-else>
            <span class="dot grey lighten-1 mr-2" />{{ status | machineToFriendlyName }}
        </template>
    </div>
</template>

<script>
    export default {
        name: 'AdStatusDots',
        props: {
            status: {
                type: String,
                required: true
            }
        }
    };
</script>

<style>
    .dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
    }
</style>