<template>
    <div>
        <v-alert
            :value="errorMessage"
            type="error"
            class="my-2">
            Please select ads to preview.
        </v-alert>

        <StyledCard>
            <template #heading>
                Campaign/Play Management
            </template>

            <template #action-buttons>
                <action-button
                    class="mr-3"
                    icon="ad"
                    target="_blank"
                    @click="gatherAdIdsForSharing()">
                    Preview Ads
                </action-button>
                <action-button
                    class="mr-3"
                    icon="outbound-link"
                    target="_blank"
                    :href="dealerFacebookAdAccountUrl">
                    Ad Account
                </action-button>
                <action-button
                    icon="refresh"
                    :loading="loadingCampaigns"
                    @click="getCampaigns()">
                    Refresh
                </action-button>
            </template>

            <div
                v-if="loadingCampaigns"
                class="text-xs-center py-5">
                <loader />
            </div>

            <styled-table
                v-else
                :headers="campaignHeaders"
                :items="treeData"
                sort-by="created_time"
                search-text="Search Campaigns"
                min-width="900px">
                <template #items="{ item, index }">
                    <tr
                        :class="[(index % 2) ? 'row-even' : 'row-odd']"
                        class="theme-border"
                        @click="toggleShowCampaign(item.id)">
                        <td class="text-xs-left pa-4">
                            <v-layout
                                align-center
                                row
                                wrap>
                                <v-flex shrink>
                                    <v-checkbox
                                        v-model="item.share"
                                        :hide-details="true"
                                        color="primary"
                                        @change="toggleShareCampaign(item.id)" />
                                </v-flex>
                                <v-flex shrink>
                                    <icon
                                        height="16"
                                        width="16"
                                        :class="{
                                            'rotate-90 toggle': !item.show,
                                            'rotate-180 toggle': item.show,
                                        }"
                                        class="mr-1"
                                        name="collapse"
                                        @click="item.show = !item.show" />
                                </v-flex>
                                <v-flex shrink>
                                    <icon
                                        class="mr-2"
                                        name="folder"
                                        height="16" />
                                </v-flex>
                                <v-flex shrink>
                                    {{ item.name }}
                                </v-flex>
                            </v-layout>
                        </td>
                        <td class="text-xs-center pa-4">
                            <ad-status-dots :status="item.effective_status" />
                        </td>
                        <td class="text-xs-center pa-4">
                            {{ item.created_time | moment('M/D/YY @ h:mma') }}
                        </td>
                        <td class="text-xs-center pa-4">
                            {{ (item.daily_budget / 100) | numFormat(numberFormats.currency) }}
                        </td>
                        <td class="text-xs-center pa-4">
                            <action-button
                                icon="outbound-link"
                                class="mx-3"
                                @click="open(item.id)">
                                Manage
                            </action-button>
                        </td>
                    </tr>


                    <template v-if="item.show">
                        <template v-for="(adset, adSetIndex) in item.adsets.data">
                            <tr
                                :key="adSetIndex"
                                :class="[(adSetIndex % 2) ? 'row-even' : 'row-odd']"
                                @click="toggleShowAdset(adset.id)">
                                <td class="text-xs-left pa-4">
                                    <v-layout
                                        row
                                        wrap
                                        style="margin-left: 20px">
                                        <v-flex shrink>
                                            <v-checkbox
                                                v-model="adset.share"
                                                :hide-details="true"
                                                color="primary"
                                                @change="toggleShareAdset(adset.id)" />
                                        </v-flex>
                                        <v-flex shrink>
                                            <icon
                                                height="16"
                                                width="16"
                                                :class="{
                                                    'rotate-90 toggle': !adset.show,
                                                    'rotate-180 toggle': adset.show,
                                                }"
                                                class="mr-1"
                                                name="collapse"
                                                @click="adset.show = !adset.show" />
                                        </v-flex>
                                        <v-flex shrink>
                                            <icon
                                                class="mr-2"
                                                name="adset"
                                                height="16" />
                                        </v-flex>
                                        <v-flex shrink>
                                            {{ adset.name }}
                                        </v-flex>
                                    </v-layout>
                                </td>
                                <td class="text-xs-center pa-4">
                                    <ad-status-dots :status="adset.effective_status" />
                                </td>
                                <td class="text-xs-center pa-4">
                                    {{ adset.created_time | moment('M/D/YY @ h:mma') }}
                                </td>
                                <td class="text-xs-center pa-4">
                                    {{ (adset.daily_budget / 100) | numFormat(numberFormats.currency) }}
                                </td>
                                <td class="text-xs-center pa-4">
                                    <action-button
                                        icon="outbound-link"
                                        class="mx-3"
                                        @click="open(item.id, adset.id)">
                                        Manage
                                    </action-button>
                                </td>
                            </tr>

                            <template v-if="adset.show">
                                <template v-for="(ad, adIndex) in adset.ads.data">
                                    <tr
                                        :key="adIndex"
                                        :class="[(adIndex % 2) ? 'row-even' : 'row-odd']">
                                        <td class="text-xs-left pa-4">
                                            <v-layout
                                                align-center
                                                row
                                                fill-height
                                                style="margin-left: 40px">
                                                <v-flex shrink>
                                                    <v-checkbox
                                                        v-model="ad.share"
                                                        :hide-details="true"
                                                        color="primary" />
                                                </v-flex>
                                                <v-flex shrink>
                                                    <img
                                                        class="mr-2"
                                                        :src="ad.creative.thumbnail_url"
                                                        alt="">
                                                </v-flex>
                                                <v-flex shrink>
                                                    {{ ad.name }}
                                                </v-flex>
                                            </v-layout>
                                        </td>
                                        <td class="text-xs-center pa-4">
                                            <ad-status-dots :status="ad.effective_status" />
                                        </td>
                                        <td class="text-xs-center pa-4">
                                            {{ ad.created_time | moment('M/D/YY @ h:mma') }}
                                        </td>
                                        <td class="text-xs-center pa-4">
                                            {{ (ad.daily_budget / 100) | numFormat(numberFormats.currency) }}
                                        </td>
                                        <td class="text-xs-center pa-4">
                                            <action-button
                                                icon="outbound-link"
                                                class="mx-3"
                                                @click="open(item.id, adset.id, ad.id)">
                                                Manage
                                            </action-button>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </template>
                </template>
            </styled-table>
        </StyledCard>
    </div>
</template>

<script>
    import Icon from '../globals/Icon';
    import StyledCard from '../globals/StyledCard';
    import StyledTable from '../globals/StyledTable';
    import Loader from '../globals/Loader';
    import { mapState, mapGetters } from 'vuex';
    import AdStatusDots from './AdStatusDots';
    import ActionButton from '../globals/ActionButton';
    import { v4 as uuidv4 } from 'uuid';
    import getNumberFormats from  '../../helpers/numberFormats';
    export default {
        name: 'CampaignTree',
        components: {
            AdStatusDots,
            ActionButton,
            Icon,
            StyledCard,
            StyledTable,
            Loader
        },
        data() {
            return {
                treeData: {},
                shareAdIds: [],
                loadingCampaigns: false,
                numberFormats: getNumberFormats(),
                errorMessage: false,
                campaignHeaders: [
                    {
                        text: 'Name',
                        align: 'left',
                        value: 'name',
                        sortable: true,
                        width: '600px'
                    },
                    {
                        text: 'Status',
                        align: 'center',
                        value: 'effective_status',
                        sortable: true,
                    },
                    {
                        text: 'Created',
                        align: 'center',
                        value: 'created_time',
                        sortable: true,
                    },
                    {
                        text: 'Current Daily Budget',
                        align: 'center',
                        value: 'daily_budget',
                        sortable: true,
                    },
                    {
                        text: '',
                        align: 'center',
                        value: '',
                        sortable: false,
                    }
                ],
            };
        },
        computed: {
            ...mapState({
                currentDealerId: (state) => state.dealer.currentDealerId,
                currentAgency: (state) => state.agency.currentAgency,
                currentUser: (state) => state.user.user,
            }),
            ...mapGetters([
                'dealerFacebookAdAccountUrl',
                'dealerFacebookAdsFilter',
                'dealerFacebookAdAccount'
            ])
        },
        created() {
            this.getCampaigns();
        },
        methods: {
            open(campaignId, adSetId, adId) {
                const url = this.dealerFacebookAdsFilter({
                    dateRange: this.dateRange,
                    campaigns: [ campaignId ],
                    adSets: [ adSetId ],
                    ads: [ adId ]
                });
                window.open(url);
            },
            async generateAdsPreviewUrl() {
                if (this.shareAdIds.length == 0) {
                    this.errorMessage = true;
                    return;
                } else {
                    this.errorMessage = false;
                }

                let uuid = uuidv4();

                let data = {
                    'key': 'ad_previews:' + uuid,
                    'agency_id': this.currentAgency.id,
                    'user_id': this.currentUser.id,
                    'public': true,
                    'data': {
                        ad_ids: this.shareAdIds,
                        ad_account: this.dealerFacebookAdAccount,
                        agency_id: this.currentAgency.id
                    }
                };

                //TODO: try catch
                //TODO: loading state
                await this.$http.post('/data-storage', data);

                let route = this.$router.resolve({ name: 'ad-previews', query: {
                        id: uuid,
                    }
                });

                window.open(route.href, '_blank');
            },
            hydrateFacebookData(data) {
                //Make sure this is not null
                data.forEach(campaign => {
                    campaign.show = false;
                    campaign.share = false;
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            adset.show = false;
                            adset.share = false;
                            if (adset.ads) {
                                adset.ads.data.forEach(ad => {
                                    ad.show = false;
                                    ad.share = false;
                                });
                            }
                        });
                    }
                });
                this.treeData = data;
            },
            gatherAdIdsForSharing() {
                this.shareAdIds = [];
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.ads) {
                                adset.ads.data.forEach(ad => {
                                    if (ad.share) {
                                        this.shareAdIds.push(ad.id);
                                    }
                                });
                            }
                        });
                    }
                });
                this.generateAdsPreviewUrl();
            },
            toggleShareCampaign(campaignId) {
                this.treeData.forEach(campaign => {
                    if (campaign.id === campaignId) {
                        campaign.show = campaign.show === false;
                        if (campaign.share === false) {
                            campaign.share = false;
                            if (campaign.adsets) {
                                campaign.adsets.data.forEach(adset => {
                                    adset.share = false;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.share = false;
                                        });
                                    }
                                });
                            }
                        } else {
                            campaign.share = true;
                            if (campaign.adsets) {
                                campaign.adsets.data.forEach(adset => {
                                    adset.share = true;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.share = true;
                                        });
                                    }
                                });
                            }
                        }
                    }
                });
            },
            toggleShareAdset(adsetId) {
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.id === adsetId) {
                                adset.show = adset.show === false;
                                if (adset.share === false) {
                                    adset.share = false;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.share = false;
                                        });
                                    }
                                } else {
                                    adset.share = true;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.share = true;
                                        });
                                    }
                                }
                            }
                        });
                    }
                });
            },
            toggleShowCampaign(campaignId) {
                this.treeData.forEach(campaign => {
                    if (campaign.id === campaignId) {
                        campaign.show = campaign.show === false;
                    }
                });
            },
            toggleShowAdset(adsetId) {
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.id === adsetId) {
                                adset.show = adset.show === false;
                            }
                        });
                    }
                });
            },
            async getCampaigns() {
                this.loadingCampaigns = true;
                try {
                    const url = `/dealers/${this.currentDealerId}/graph-api/ad-account/campaigns?fields=name,daily_budget,effective_status,adlabels,created_time,adsets{id,name,created_time,daily_budget,effective_status,ads{id,name,created_time,daily_budget,effective_status,creative.thumbnail_height(32).thumbnail_width(32){id,thumbnail_url}}}`;
                    const response = await this.$http.get(url);
                    this.hydrateFacebookData(response.data);
                    this.loadingCampaigns = false;
                } catch (error) {
                    console.log('Error loading dealer campaigns', error);
                    this.error = 'Error loading dealer campaigns';
                    this.loadingCampaigns = false;
                }
            },
        }
    };
</script>

<style>

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.toggle {
    transition: transform 0.2s ease-in-out;
}

/*.theme-border {*/
/*    border-left: 5px solid var(--color-primary);*/
/*}*/
</style>